import Service from '@/shared/classes/service'
import { getNodeApiUrlByVersion } from "@/config";

export default class AssignedHseRisksService extends Service {
  static prefix = 'assigned-hse-risks'

  static destroy(id: string): Promise<any> {
    return this.delete(`${getNodeApiUrlByVersion()}/${ this.prefix }/${ id }`)
  }

  static destroyMultiple(ids: string[]): Promise<any> {
    const qs = new URLSearchParams();
    ids.forEach(id => qs.append('ids', id))
    return this.delete(`${getNodeApiUrlByVersion()}/${ this.prefix }?${qs.toString()}`)
  }

  static show(id: string): Promise<any> {
    return this.get(`${getNodeApiUrlByVersion()}/${ this.prefix }/${ id }`)
  }

  static patchMultiple(body: any): Promise<any> {
    return this.patch(`${getNodeApiUrlByVersion()}/${ this.prefix }`, body)
  }
}
