







import { Component, Prop, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import AssignedHseRisks from "@/shared/modules/assigned-hse-risks/assigned-hse-risks.model";

const matrix = [
  [1, 2, 3, 4, 5],
  [2, 4, 6, 8, 10],
  [3, 6, 9, 12, 15],
  [4, 8, 12, 15, 20],
  [5, 10, 15, 20, 25],
]

@Component({
  methods: {
    __
  }
})
export default class RiskAssessmentAssignedLevelHeader extends Vue {
 @Prop() item!: AssignedHseRisks

  get levelValue() {
    if (!this.item.probability || !this.item.consequences) return 0;
    return matrix[this.item.probability - 1][this.item.consequences - 1];
  }

  get levelColor() {
    const value = this.levelValue;
    if (value >= 10) return "rgb(203, 37, 50)";
    if (value >= 5) return "rgb(239, 196, 57)";
    if (value >= 1) return "rgb(35, 126, 81)";

    return "transparent"
  }
}
