









import { Component, Vue } from "vue-property-decorator";
import __ from '@/shared/helpers/__'
import baseHttp from "@/shared/http";
import { getNodeApiUrlByVersion } from "@/config";
import { CompanyRoutes } from "@/shared/router/company";

@Component({})
export default class SignHandbookButton extends Vue {
  http = baseHttp
  handbookUuid = ''
  text = ''
  loading = true

  created() {
    this.http.get(`${getNodeApiUrlByVersion()}/hse-handbook-signatures/last-handbook-signature-status`)
      .then(res => {
        if (res.data?.signatureStatus === 2) this.text = __('company.components.hse-handbook.sign-button.read');
        else this.text = __('company.components.hse-handbook.sign-button.sign');
        this.handbookUuid = res.data?.handbookUuid;
      })
      .finally(() => this.loading = false);
  }

  navigate() {
    if (!this.handbookUuid) return;
    this.$router.push({ name: CompanyRoutes.hseHandbookSigning, params: { uuid: this.handbookUuid } })
  }
}
