























import { Component, Vue } from "vue-property-decorator";
import DataTable from "@/shared/components/data-table/DataTable.vue";
import DataTableBase from "@/shared/classes/components/data-table/data-table";
import DataTableHeader from "@/shared/classes/components/data-table/data-table-header";
import __ from "@/shared/helpers/__";
import EditAction from "@/shared/classes/components/data-table/default-actions/edit-action";
import Company from "@/shared/modules/company/company.model";
import { CompanyGetters } from "@/shared/store/company/company.getters";
import DeleteAction from "@/shared/classes/components/data-table/default-actions/delete-action";
import { GlobalActions } from "@/shared/store/global/global.actions";
import { SnackBarTypes } from "@/shared/helpers/snack-bar.helper";
import AreYouSureDialog from "@/shared/components/dialogs/AreYouSureDialog.vue";
import { CompanyRoutes } from "@/shared/router/company";
import { getNodeApiUrlByVersion } from "@/config";
import AssignedHseRisks from "@/shared/modules/assigned-hse-risks/assigned-hse-risks.model";
import { getVersion } from "@/shared/helpers/version.helper";
import moment from "moment";
import RiskAssessmentAssignedLevelHeader
  from "@/company/components/risk-assessments/RiskAssessmentAssignedLevelHeader.vue";
import RiskCategorySelect from "@/company/components/risk-assessments/RiskCategorySelect.vue";
import AssignedHseRisksService from "@/shared/modules/assigned-hse-risks/assigned-hse-risks.service";
import SelectAction from "@/shared/classes/components/data-table/data-table-select-actions";
import SelectedRiskModify from "@/company/components/risk-assessments/SelectedRiskModify.vue";
import { RoleLevel } from "@/shared/modules/role/role.model";
import roleCan from "@/shared/helpers/role-can.helper";
import RoleCan from "@/shared/components/RoleCan.vue";
import Model from "@/shared/classes/model";
import IModelResponse from "@/shared/interfaces/modules/model-response.interface";
import DataTableAction from "@/shared/classes/components/data-table/data-table-action";
import DataTableFilter, { FilterOperators } from "@/shared/classes/components/data-table/data-table-filter";
import SelectField from "@/shared/classes/form/fields/Select/select-field";
import SearchableField from "@/shared/classes/form/fields/Select/searchable-field";
import Field from "@/shared/classes/form/field";
import { FieldTypes } from "@/shared/components/form/field-types";
import SignHandbookButton from "@/company/components/hse-handbook/SignHandbookButton.vue";
import SelectItem from "@/shared/classes/form/fields/Select/select-item";


@Component({
  methods: { __ },
  components: { DataTable, RiskCategorySelect, RoleCan, SignHandbookButton },
})
export default class AssignedRiskAssessments extends Vue {
  RoleLevel = RoleLevel
  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
      .setModel(AssignedHseRisks)
      .setEndpoint(`${getNodeApiUrlByVersion()}/assigned-hse-risks`)
      .setGroupBy('hseRiskCategoryName')
      .setGroupDesc(false)
      .setPaginationNotVisible(true)
      .setMultipleCheckbox(roleCan([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin]))
      .setDoubleClick(this.navigateToEdit)
      .setGroupSelect(roleCan([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin]))
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setSortable(false)
          .setText(__('company.views.risk-assessments.assigned.table.columns.name')),
        new DataTableHeader()
          .setSortable(false)
          .setText(__('company.views.risk-assessments.assigned.table.columns.level'))
          .setValueComponent(RiskAssessmentAssignedLevelHeader),
        new DataTableHeader()
          .setKey('responsibleUserName')
          .setSortable(false)
          .setText(__('company.views.risk-assessments.assigned.table.columns.responsible')),
        new DataTableHeader()
          .setKey('assessmentDate')
          .setSortable(false)
          .setText(__('company.views.risk-assessments.assigned.table.columns.assessment-date')),
        new DataTableHeader()
          .setKey('barriers')
          .setSortable(false)
          .setText(__('company.views.risk-assessments.assigned.table.columns.barrier-deadline'))
          .setWidth('180px')
          .setValueParser((value) => {
            const dates: string[] = []
            value?.forEach((barrier: any) => {
              const date = moment(barrier.date).format(getVersion() === 'no' ? 'DD-MM-YYYY' : 'YYYY-MM-DD');
              if (!dates.includes(date)) dates.push(date)
            })
            return dates.sort().join(', ')
          }),
        new DataTableHeader()
          .setSortable(false)
          .setText(__('company.views.risk-assessments.assigned.table.columns.barrier-status'))
          .setValueParser((value: number, item: AssignedHseRisks) => {
            if (item.status === 1) return __('company.components.risk-assessments.selected-assign.form.barrier-status-options.in-progress')
            if (item.status === 2) return __('company.components.risk-assessments.selected-assign.form.barrier-status-options.completed')
          })
      ])
      .setSelectActions([
        new SelectAction()
          .setComponent(SelectedRiskModify)
          .setEnabled(true)
          .setMeta({
            onSubmit: (data: any) => {
              const ids = this.table?.selected.map(el => el.uuid)
              this.updateMultiple({ ...data, uuids: ids })
            },
            onDelete: () => this.deleteMultiple((this.table?.selected || []) as AssignedHseRisks[])
          })
      ])
      .setActions([
        new EditAction()
          .setAllowedForRole([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin])
          .setAction(this.navigateToEdit),
        new DeleteAction()
          .setAllowedForRole([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin])
          .setAction(this.delete),
        new DataTableAction()
          .setIcon('mdi-eye')
          .setAllowedForRole([RoleLevel.employee])
          .setAction((item: Model<IModelResponse>) => {
            this.$router.push({ name: CompanyRoutes.assignedRiskView, params: { uuid: item.uuid } })
          })
      ])
      .setFilters([
        new DataTableFilter()
          .setOperator(FilterOperators.gte)
          .setKeyIsPrimary()
          .setField(
            new Field()
              .setType(FieldTypes.datePicker)
              .setKey('assessmentDate')
              .setTitle(__('company.views.risk-assessments.assigned.table.filters.assessment-from'))
          ),
        new DataTableFilter()
          .setOperator(FilterOperators.lte)
          .setKeyIsPrimary()
          .setField(
            new Field()
              .setType(FieldTypes.datePicker)
              .setKey('assessmentDate')
              .setTitle(__('company.views.risk-assessments.assigned.table.filters.assessment-to'))
          ),
        new DataTableFilter()
          .setOperator(FilterOperators.gte)
          .setKeyIsPrimary()
          .setField(
            new Field()
              .setType(FieldTypes.datePicker)
              .setKey('deadline')
              .setTitle(__('company.views.risk-assessments.assigned.table.filters.deadline-from'))
          ),
        new DataTableFilter()
          .setOperator(FilterOperators.lte)
          .setKeyIsPrimary()
          .setField(
            new Field()
              .setType(FieldTypes.datePicker)
              .setKey('deadline')
              .setTitle(__('company.views.risk-assessments.assigned.table.filters.deadline-to'))
          ),
        new DataTableFilter()
          .setKeyIsPrimary()
          .setField(
            new SelectField()
              .setKey('status')
              .setItems([
                new SelectItem()
                  .setValue(1)
                  .setTitle(__('company.components.risk-assessments.selected-assign.form.barrier-status-options.in-progress')),
                new SelectItem()
                  .setValue(2)
                  .setTitle(__('company.components.risk-assessments.selected-assign.form.barrier-status-options.completed')),
              ])
              .setTitle(__('company.views.risk-assessments.assigned.table.filters.status'))
          ),
      ])

    this.table.classes.tableCard = 'elevation-0'
    this.table.noPadding = true
  }

  navigateToCreate(): void {
    this.$router.push({ name: CompanyRoutes.assignedRiskCreate })
  }

  navigateToEdit(item: AssignedHseRisks): void {
    if (roleCan([RoleLevel.manager, RoleLevel.leader, RoleLevel.superadmin])) {
      this.$router.push({ name: CompanyRoutes.assignedRiskEdit, params: { uuid: item.uuid } })
    } else {
      this.$router.push({ name: CompanyRoutes.assignedRiskView, params: { uuid: item.uuid } })
    }
  }

  delete(item: AssignedHseRisks): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.risk-assessments.assigned.table.actions.delete.title'),
        text: __('company.views.risk-assessments.assigned.table.actions.delete.text', { name: item.name }),
        onYes: () => AssignedHseRisksService.destroy(item.uuid)
          .then(() => {
            this.table?.removeItem(item)
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.views.risk-assessments.assigned.table.actions.delete.on-success')
            })
          })
      }
    })
  }

  deleteMultiple(items: AssignedHseRisks[]): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('company.views.risk-assessments.assigned.table.actions.delete-multiple.title'),
        text: __('company.views.risk-assessments.assigned.table.actions.delete-multiple.text', { name: '' }),
        onYes: () => AssignedHseRisksService.destroyMultiple(items.map(el => el.uuid))
          .then(() => {
            items.forEach(el => this.table?.removeItem(el))
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('company.views.risk-assessments.assigned.table.actions.delete-multiple.on-success')
            })
          })
      }
    })
  }

  updateMultiple(data: any): void {
    AssignedHseRisksService.patchMultiple(data)
      .then(() => {
        this.$store.dispatch(GlobalActions.showSnackBar, {
          type: SnackBarTypes.success,
          message: __("company.views.risk-assessments.assigned.table.actions.update.on-success"),
        });
        this.tableRef?.refresh();
      });
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
