




import { Component, Vue } from 'vue-property-decorator'
import SearchableField from '@/shared/classes/form/fields/Select/searchable-field'
import FormField from '@/shared/components/form/FormField.vue'
import can from '@/shared/helpers/can.helper'
import __ from '@/shared/helpers/__'
import { getNodeApiUrlByVersion } from "@/config";
import _ from "lodash";
import { getQueryName } from "@/shared/helpers/query.helper";
import { FilterOperators } from "@/shared/classes/components/data-table/data-table-filter";

@Component({
  components: { FormField },
  methods: { can }
})
export default class RiskCategorySelect extends Vue {
  categorySelect: SearchableField | null = null

  created() {
    this.categorySelect = new SearchableField()
      .setEntryKey('hseRiskCategoryUuid')
      .setTitle(__('company.components.risk-assessments.category-select-title'))
      .loadItems({
        endpoint: `${getNodeApiUrlByVersion()}/hse-risk-categories`,
        value: 'uuid',
        title: 'name',
        perPage: 100,
      })
      .setHideDetails(true)
      .setDense(true)
      .setValue(this.$router.currentRoute.query?.[getQueryName(FilterOperators.equals, 'hseRiskCategoryUuid')])
      .setOnChange((value) => {
        let { query, name }: any = this.$router.currentRoute
        query = { ...query }
        if (value) _.set(query, getQueryName(FilterOperators.equals, 'hseRiskCategoryUuid'), value)
        else delete query[getQueryName(FilterOperators.equals, 'hseRiskCategoryUuid')];

        this.$router.push({ name, query })
          .catch(() => {
          })
      })
  }
}
